import React from 'react';
import { Link } from 'gatsby';
import '../../styles/gst.css';
import Testimonial from '../../components/Testimonial';
import FAQAccord from '../../components/GFlush';
import Layout from '../../components/Layout';
import { Seo } from '../../components/seo';
// import Form, { SideBar } from "../../components/GSTTemp/Form";
import Guidance from '../../components/GSTTemp/Guidance';
import Package from '../../components/GSTTemp/Package';
// import Hero from "../../components/Hero";
import AboutWithForm from '../../components/GSTTemp/AboutWithForm';
import Cta from '../../components/Cta';

export default function Deduction() {
  /* Slider */
  // const dedSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Online 80C and 80 Deductions`,
  //     content: `Secure and fast 80C registration and deductions`,
  //     image: "/imgs/business/sliders/subsidiary-slider.png",
  //     alt_tag: "Online 0C and 80 Deductions Near Banglore",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `80C and 80 Deduction`,
    buyBtnLink: `#pricing-buy`,
    price: `6999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };

  /* About Data With Form*/
  const dedAboutData = {
    header: `80C And 80C Deductions`,
    sub_title: `FOR ONLINE 80C AND 80C DEDUCTIONS.`,
    content_paragraph: [
      `Part 80C is a popular and well-liked section among taxpayers because it allows them to lowe r their 
      taxable income by making tax-saving investments or incurring qualified costs. It enables a maximum 
      deduction of Rs 1.5 lakh from the taxpayer's total income per year. Individuals and HUFs can both profit 
      from this deduction. This deduction is not available to corporations, partnership firms, or LLPs.`,

      // `Although the 80C deduction is the most well-known, there are additional deductions that can help
      // people decrease their tax bill. Let us examine these deductions in greater depth: `,
    ],
  };
  var FAQ_data = [
    {
      header: 'Who is eligible for rebate u/s 87a?',
      body: [
        {
          content: `For the fiscal years 2020–21 and 2021–22, a Taxpayer may only make
        a claim for a rebate under section 87A if the following
        requirements are met:`,
          icon: false,
        },
        {
          content: ` You are a resident individual, thus HUF and businesses are not
        eligible for this reimbursement.`,
          icon: true,
        },
        {
          content: `Your annual total income, less any deductions allowed by Chapter
        VI-A (Sections 80C, 80D, and so on), cannot exceed Rs. 5 lakh.`,
          icon: true,
        },
      ],
    },
    // 2
    {
      header: 'Who may file an 80GG deduction claim?',
      body: [
        {
          content: `Employees who do not receive HRA as a component of their income
          due to employment in the unorganized sector or to self-employed
          individuals are eligible for deduction under 80GG. The person
          requesting this deduction should not be the owner of a home there.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How is 80GG calculated? How can I get my 80GG?',
      body: [
        {
          content: `80GG reduction will be permitted as the least of the following:`,
          icon: false,
        },
        {
          content: `5,000 rupees per month`,
          icon: true,
        },
        {
          content: `25% of the total adjusted income, (less deductions allowed under
            sections 80C to 80U and long-term capital gains, short-term
            capital gains under section 111A, and income under section 115A or
            115D. Income is also prior to claiming a deduction under section
            80GG).`,
          icon: true,
        },
      ],
    },
    // 3
    {
      header:
        'What does the tax code 80GG mean? What is paid in rent under 80GG?',
      body: [
        {
          content: `Even if your pay does not contain an HRA component or if you are a
          self-employed person with income in addition to your salary, you
          are still able to deduct rent paid under section 80GG. To qualify
          for a deduction under section 80GG, you must not own any
          residential property where you live.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: 'Can you submit a section 80 HRA claim?',
      body: [
        {
          content: `Yes, you can deduct your rent payments under section 80GG if you
          do not receive HRA as a part of your compensation. However, the
          maximum deduction permitted is Rs 60,000 annually.`,
          icon: false,
        },
      ],
    },
    // 5
    {
      header: 'Does Section 80TTB exclude my FD interest?',
      body: [
        {
          content: `Your interest income from a fixed deposit is exempt from taxation
          if you are a senior citizen over 60 according to Section 80TTB.`,
          icon: false,
        },
      ],
    },
    // 6
    {
      header: ' What do you mean by the chapter VI A deduction known as 80C?',
      body: [
        {
          content: `When a taxpayer makes certain investments or eligible expenditures
          permitted under Chapter VI A, the income tax department permits a
          reduction in the taxpayer's taxable income. For example, Section
          80C permits deductions for investments made in PPF, EPF, LIC
          premiums, Equity Linked Savings Plans, principal loan payments,
          stamp duty and registration fees for property purchases, Sukanya
          Smriddhi Yojana (SSY), National Savings Certificate (NSC), and
          Senior Citizen Savings Plans.`,
          icon: false,
        },
      ],
    },
    // 7
    {
      header: `Which year can I deduct the stamp duty I paid when buying a house or
      other property?`,
      body: [
        {
          content: `In accordance with Section 80C, you may proceed with the claim for
          stamp duty paid on the purchase of a home in the year in which the
          payment is made.`,
          icon: false,
        },
      ],
    },
    /*8*/
    {
      header:
        'Can a business deduct gifts made in accordance with Section 80G?',
      body: [
        {
          content: `Any taxpayer who makes a donation to one of the above
          organizations, funds, etc. qualifies for a deduction under Section
          80G.`,
          icon: false,
        },
      ],
    },
    /* 9 */
    {
      header: `  Can I claim the 80C deductions if I don't have any proof to give my
      employer when I file my return?`,
      body: [
        {
          content: `Before the conclusion of a Financial Year (FY), investment proofs
          are sent to the employer so that they can take these investments
          into account when calculating your taxable income and the
          necessary tax reduction. However, even if you forget to give your
          employer these proofs, you can still claim the money you invested
          as long as it was done before the end of the applicable FY when
          you file your income tax return.`,
          icon: false,
        },
      ],
    },
    /* 10 */
    {
      header: ` Is there a restriction or a cap on how much money I can deduct under
      Section 80E?`,
      body: [
        {
          content: `There is no upper limit set forth by law for Section 80E deduction
          claims. Therefore, it is possible to deduct the real interest paid
          over the course of a year.`,
          icon: false,
        },
      ],
    },
    /* 11 */
    {
      header: 'Can a business or firm benefit from Section 80C?',
      body: [
        {
          content: `Section 80C's requirements only apply to individuals or Hindu
          Undivided Families (HUF). As a result, a business or firm cannot
          benefit from Section 80C.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='80C and 80 Deductions'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={dedSlider} /> */}
          <Package packageData={PackageCompData} />
          <AboutWithForm about_content={dedAboutData} />

          {/* <!-- Banner section end --> */}
          <section className='h-link'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-1'></div>
                <div className='head col-md-10'>
                  <div className='links'>
                    <Link to='#Section80C' className='link scrollto'>
                      <div className='col-md-3 col-sm-6 h-links'>
                        <img src='/imgs/icons/it/80cc.png' alt='images' />
                        <br />
                        Section80C
                      </div>
                    </Link>
                    <Link to='#Section80CCC' className='link scrollto'>
                      <div className='col-md-3  col-sm-6 h-links'>
                        <img src='/imgs/icons/it/80u.png' alt='images' />
                        <br />
                        Section80CCC
                      </div>
                    </Link>

                    <Link to='#Section80-deduction' className='link scrollto'>
                      <div className='col-md-3 col-sm-6 h-links'>
                        <img src='/imgs/icons/it/80gg.png' alt='images' />
                        <br />
                        80Deductions
                      </div>
                    </Link>

                    <Link to='#Section80-TTA' className='link scrollto'>
                      <div className='col-md-3 col-sm-6 h-links'>
                        <img src='/imgs/icons/it/80dd.png' alt='images' />
                        <br />
                        Section80TTA
                      </div>
                    </Link>

                    <Link to='#Section80GG' className='link scrollto'>
                      <div className='col-md-3 col-sm-6 h-links'>
                        <img src='/imgs/icons/it/80gg.png' alt='images' />
                        <br />
                        Section80GG
                      </div>
                    </Link>

                    <Link to='#Section80E' className='link scrollto'>
                      <div className='col-md-3 col-sm-6 h-links'>
                        <img src='/imgs/icons/it/80tta.png' alt='images' />
                        <br />
                        Section80E
                      </div>
                    </Link>

                    <Link to='#Section80EE' className='link scrollto'>
                      <div className='col-md-3 col-sm-6 h-links'>
                        <img src='/imgs/icons/it/80cc.png' alt='images' />
                        <br />
                        Section80EE
                      </div>
                    </Link>

                    <Link to='#Section80D' className='link scrollto'>
                      <div className='col-md-3 col-sm-6 h-links'>
                        <img src='/imgs/icons/it/deduction.png' alt='images' />
                        <br />
                        Section80D
                      </div>
                    </Link>

                    <Link to='#Section80DD' className='link scrollto'>
                      <div className='col-md-3 col-sm-6 h-links'>
                        <img src='/imgs/icons/it/80dd.png' alt='images' />
                        <br />
                        Section80DD
                      </div>
                    </Link>

                    <Link to='#Section80DDB' className='link scrollto'>
                      <div className='col-md-3 col-sm-6 h-links'>
                        <img src='/imgs/icons/it/80gc.png' alt='images' />
                        <br />
                        Section80DDB
                      </div>
                    </Link>

                    <Link to='#Section80U' className='link scrollto'>
                      <div className='col-md-3 col-sm-6 h-links'>
                        <img src='/imgs/icons/it/80u.png' alt='images' />
                        <br />
                        Section80U
                      </div>
                    </Link>

                    <Link to='#Section80G' className='link scrollto'>
                      <div className='col-md-3 col-sm-6 h-links'>
                        <img src='/imgs/icons/it/80gg.png' alt='images' />
                        <br />
                        Section80G
                      </div>
                    </Link>

                    <Link to='#Section80GGB' className='link scrollto'>
                      <div className='col-md-3 col-sm-6 h-links'>
                        <img src='/imgs/icons/it/80cc.png' alt='images' />
                        <br />
                        Section80GGB
                      </div>
                    </Link>

                    <Link to='#Section80GGC' className='link scrollto'>
                      <div className='col-md-3 col-sm-6 h-links'>
                        <img src='/imgs/icons/it/80u.png' alt='images' />
                        <br />
                        Section80GGC
                      </div>
                    </Link>

                    <Link to='#Section80RRB' className='link scrollto'>
                      <div className='col-md-3 col-sm-6 h-links'>
                        <img src='/imgs/icons/it/80tta.png' alt='images' />
                        <br />
                        Section80RRB
                      </div>
                    </Link>

                    <Link to='#Section80TTB' className='link scrollto'>
                      <div className='col-md-3 col-sm-6 h-links'>
                        <img src='/imgs/icons/it/80gc.png' alt='images' />
                        <br />
                        Section80TTB
                      </div>
                    </Link>
                  </div>
                </div>
                <div className='col-md-1'></div>
              </div>
            </div>
          </section>
          <section className='main-content'>
            <div className='container mt-5'>
              <div className='row mt-5'>
                <div className='col-md-1'></div>
                <div className='col-lg-10 col-md-10 col-sm-12 content'>
                  <div className='desc ipr'>
                    <section id='Section80C' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>Section 80C - Investment Deductions</h2>
                        </header>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          Section 80C is a popular and well-liked section among
                          taxpayers because it allows them to lower their
                          taxable income by making tax-saving investments or
                          incurring qualified costs. It enables a maximum
                          deduction of Rs 1.5 lakh from the taxpayer's total
                          income per year.
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          Individuals and HUFs can both profit from this
                          deduction. This deduction is not available to
                          corporations, partnership firms, or LLPs. Section 80C
                          contains the subsections 80CCC, 80CCD (1), 80CCD (1b),
                          and 80CCD (2b) (2).
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          It is vital to remember that the overall ceiling for
                          claiming deduction, including subsections, is Rs 1.5
                          lakh, with an additional deduction of Rs 50,000
                          allowed under Section 80CCD (1b)
                        </p>
                      </div>
                    </section>

                    <section id='Section80CCC' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>
                            Section 80CCC – Insurance Premium /Section 80CCD –
                            Pension Contribution
                          </h2>
                        </header>

                        <table className='table table-hover table-striped table-bordered'>
                          <thead className='bg-info text-white'>
                            <tr>
                              <td></td>
                              <td>
                                <strong>
                                  Eligible investments for tax deductions
                                </strong>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <strong>80C</strong>
                              </td>
                              <td>
                                PPF, EPF, LIC premium, Equity linked saving
                                scheme, principal amount payment towards home
                                loan, stamp duty and registration charges for
                                property purchase, Sukanya smriddhi yojana
                                (SSY), National saving certificate (NSC), Senior
                                citizen savings scheme (SCSS), ULIP, tax saving
                                FD for 5 years, Infrastructure bonds, etc. are
                                all eligible for deduction under Section 80C.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>
                                  80CCC Deduction for life insurance annuity
                                  plan.
                                </strong>
                              </td>
                              <td>
                                Pension received from the annuity or sum
                                received upon surrendering the annuity,
                                including interest or bonus accrued on the
                                annuity, is taxable in the year of receipt
                                according to section 80CCC of the tax code.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>80CCD (1) Deduction for NPS</strong>
                              </td>
                              <td>
                                <p>
                                  <strong>
                                    Contribution made by the employee
                                  </strong>
                                  under Section 80CCD (1) The following are the
                                  minimum permissible deductions as the maximum
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>10%
                                  of salary (in case taxpayer is employee)
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>20&
                                  of gross total income (in case of self
                                  employed)
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>Rs
                                  1.5 Lakh ( limit allowed u/s 80C)
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <strong>80CCD (1b) Deduction for NPS</strong>
                              </td>
                              <td>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Additional deduction of Rs 50,000 is allowed
                                  for amount deposited to NPS account
                                </p>

                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Contributions to Atal Pension Yojana is also
                                  eligible for deduction.
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>80CCD (2) Deduction for NPS</strong>
                              </td>
                              <td>
                                Under this section,
                                <strong>employers' contributions</strong> may be
                                deducted up to 10% of the basic pay plus the
                                depreciation allowance. Only those receiving
                                salaries are eligible for the benefits in this
                                area; self-employed people are not.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </section>

                    <section id='Section80-deduction' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>Section 80C Deductions List</h2>
                        </header>

                        <p>
                          Here are some investment options that are allowed as
                          deduction u/s 80C. They not only help you with saving
                          taxes but also help you grow your money. A quick
                          comparison for the options is tabulated below :
                        </p>
                        <table className='table table-hover table-striped table-bordered'>
                          <thead className='bg-info text-white'>
                            <tr>
                              <td>
                                <strong>Investment options</strong>
                              </td>
                              <td>
                                <strong>Average Interest</strong>
                              </td>
                              <td>
                                <strong>Lock in period for</strong>
                              </td>
                              <td>
                                <strong>Risk factor</strong>
                              </td>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>
                                <strong>ELSS funds</strong>
                              </td>
                              <td>12% – 15%</td>
                              <td>3 years</td>
                              <td>High</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>NPS Scheme</strong>
                              </td>
                              <td>8% – 10%</td>
                              <td>Till 60 years of age</td>
                              <td>High</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>ULIP</strong>
                              </td>
                              <td>8% – 10%</td>
                              <td>5 years</td>
                              <td>Medium</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Tax saving FD</strong>
                              </td>
                              <td>7% – 8%</td>
                              <td>5 years</td>
                              <td>Low</td>
                            </tr>

                            <tr>
                              <td>
                                <strong>PPF</strong>
                              </td>
                              <td>7.10%</td>
                              <td>5 years</td>
                              <td>Low</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Senior citizen savings scheme</strong>
                              </td>
                              <td>7.4%</td>
                              <td>
                                5years
                                <small>
                                  (can be extended for other 3 years)
                                </small>
                              </td>
                              <td>Low</td>
                            </tr>

                            <tr>
                              <td>
                                <strong>National</strong>
                              </td>
                              <td>6.8%</td>
                              <td>5 years</td>
                              <td>Low</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Sukanya Samriddhi Yojana</strong>
                              </td>
                              <td>8.4%</td>
                              <td>
                                Till girl child reaches 21 years of age <br />
                                <small>
                                  (partial withdrawal allowed when she reached
                                  18 years)
                                </small>
                              </td>
                              <td>Low</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </section>

                    <section id='Section80-TTA' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>Section 80 TTA – Interest on Savings Account</h2>
                        </header>
                        <p>
                          Interest on savings bank accounts is deducted from
                          gross total income.
                        </p>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          You may deduct up to Rs 10,000 from your interest
                          income from savings accounts with banks, co-operative
                          societies, or post offices if you are an individual or
                          HUF. Do not forget to subtract interest from savings
                          account from other income.
                        </p>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          Fixed deposit, recurring deposit, and corporate bond
                          interest income are not eligible for the section 80TTA
                          deduction.
                        </p>
                      </div>
                    </section>

                    <section id='Section80GG' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>Section 80GG – House Rent Paid</h2>
                        </header>

                        <h5>
                          <strong>
                            Deduction for House Rent Paid in Case of Non-HRA
                          </strong>
                        </h5>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          When HRA is not received, a Section 80GG deduction is
                          possible for rent paid. The taxpayer should not
                          possess a residence near their place of employment,
                          nor should their spouse or small kid.
                        </p>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          The taxpayer should not own any other self-occupied
                          residential property.
                        </p>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          The taxpayer must pay and live in a rental.
                        </p>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          Everyone is eligible for the deduction.
                        </p>

                        <h5>
                          <strong>
                            The following are the minimum deductions that are
                            available:
                          </strong>
                        </h5>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          Rent paid less 10% of total income after adjustments
                        </p>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          5,000 rupees per month
                        </p>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          25% of total adjusted income
                        </p>

                        <p>
                          <span className='text-warning'>Note: </span>
                          Adjusted Gross Total Income is obtained by subtracting
                          certain deductions, exempt income, long-term capital
                          gains, and income from non-residents and foreign
                          corporations from the Gross Total Income.
                        </p>

                        <p>
                          Because the restrictions are automatically computed,
                          using online ITR e-filing software like that from
                          TODAYFILINGS can be incredibly simple. Therefore, you
                          do not need to worry about performing difficult
                          computations.
                        </p>
                        <p>
                          From FY 2016–17 onward, the monthly deduction amount
                          has increased from Rs 2,000 to Rs 5,000.
                        </p>
                      </div>
                    </section>

                    <section id='Section80E' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>Section 80E – Interest on Education Loan</h2>
                        </header>
                        <p>
                          <strong>
                            Interest on higher education loans can be deducted
                          </strong>
                          .
                        </p>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          An individual is permitted to deduct the interest paid
                          on loans received to pursue higher education. This
                          loan may have been taken on behalf of the taxpayer,
                          their spouse, their children, or a student over whom
                          they have legal custody.
                        </p>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          The 80E deduction is available for a maximum of 8
                          years (starting in the year when interest repayment
                          begins), or until all interest has been paid,
                          whichever comes first. The amount that may be claimed
                          is not constrained.
                        </p>
                      </div>
                    </section>

                    <section id='Section80EE' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>Section 80EE – Interest on Home Loan</h2>
                        </header>

                        <h5>
                          <strong>
                            Deductions on Home Loan Interest for First Time Home
                            Owners
                          </strong>
                        </h5>

                        <h6>
                          <strong>FY 2017-18 and FY 2016-17</strong>
                        </h6>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          If the loan was taken out in FY 2016–17, this
                          deduction is available in FY 2017–18.
                        </p>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          Only homeowners (individuals) with a single residence
                          on the day the loan was approved are eligible for the
                          deduction under section 80EE. The home loan must be
                          for less than Rs 35 lakh, and the property value must
                          be less than Rs 50 lakh. The financial institution's
                          loan must have been approved between April 1, 2016,
                          and March 31, 2017.
                        </p>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          In addition to the Rs 2 lakh deduction (on the
                          interest component of the house loan EMI) permitted
                          under section 24 there is an additional deduction of
                          Rs 50,000 available on your home loan interest.
                        </p>

                        <h6>
                          <strong>FY 2013-14 and FY 2014-15</strong>
                        </h6>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          The first-time home valued no more than Rs 40 lakh
                          qualified for this section's deduction for the
                          relevant financial years. You can only use this if
                          your total loan during this time is less than Rs 25
                          lakh.
                        </p>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          Loan approval must occur between April 1, 2013, and
                          March 31, 2014. The total deduction permitted under
                          this clause, which is permitted for the fiscal years
                          2013–2014 and 2014–2015, cannot exceed Rs 1 lakh.
                        </p>
                      </div>
                    </section>

                    <section id='Section80D' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>Section 80D – Medical Insurance</h2>
                        </header>
                        <h5>
                          <strong>
                            Deduction for the premium paid for Medical Insurance
                          </strong>
                          .
                        </h5>

                        <p>
                          Section 80D allows you (as a person or HUF) to deduct
                          Rs. 25,000 for insurance for yourself, your spouse,
                          and your dependent children. If your parents are under
                          60, you may also deduct an additional Rs 25,000 for
                          their insurance. In the 2018 Budget, this sum was
                          doubled from Rs 30,000 to Rs 50,000 for parents who
                          are older than 60.
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          If both the taxpayer and the taxpayer's parent(s) are
                          60 years of age or older, the maximum deduction
                          allowed by this clause is Rs. 1 lakh.
                        </p>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          <strong>Example: </strong>Both Rahul and his father
                          are 65 years old. The most Rahul may deduct in this
                          situation under section 80D is Rs. 100,000.
                        </p>

                        <p>
                          A cumulative additional deduction of Rs. 5,000 is
                          permitted for preventive health checks starting in the
                          fiscal year 2015–16.
                        </p>
                      </div>
                    </section>

                    <section id='Section80DD' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>Section 80DD – Disabled Dependent</h2>
                        </header>
                        <h5>
                          <strong>
                            Deduction for Rehabilitation of Handicapped
                            Dependent Relative
                          </strong>
                          .
                        </h5>

                        <p>
                          Section 80DD deduction is available to a resident
                          individual or a HUF and is available on
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          Expenditures made for a dependent, handicapped
                          person's training, medical care (including nursing
                          care), and rehabilitation
                        </p>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          Payment or deposit made to a designated scheme for a
                          disabled dependent relative's maintenance.
                        </p>
                        <span>
                          <p>
                            <i className='bi bi-check-circle-fill'></i>Where
                            disability is at least 40% but not more than 80%, a
                            fixed deduction of Rs. 75,000 will be made.
                          </p>
                          <p>
                            <i className='bi bi-check-circle-fill'></i>There is
                            a fixed deduction of Rs. 1,25,000 in cases of severe
                            disability (disability of 80% or more).
                          </p>
                        </span>

                        <p>
                          A certificate of incapacity from a recognized medical
                          authority is necessary to claim this deduction.
                        </p>
                        <p>
                          As of FY 2015–16, the deduction thresholds of Rs.
                          50,000 and Rs. 1,000,000 have been increased to Rs.
                          75,000 and Rs. 1,25,000, respectively.
                        </p>
                      </div>
                    </section>

                    <section id='Section80DDB' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>Section 80DDB – Medical Expenditure</h2>
                        </header>
                        <h5>
                          <strong>
                            Deduction for Medical Expenditure on Self or
                            Dependent Relative
                          </strong>
                        </h5>

                        <h6>
                          <strong>For individuals and HUFs below age 60</strong>
                        </h6>
                        <p>
                          A resident individual or a HUF is eligible for a
                          deduction of up to Rs. 40,000. It can be used to cover
                          any costs associated with the treatment of specific
                          medical conditions for the owner or any of his
                          dependents. Such a deduction is possible for a HUF in
                          relation to medical costs related to these designated
                          illnesses for any HUF member.
                        </p>

                        <h6>
                          <strong>
                            For senior citizens and super senior citizens
                          </strong>
                        </h6>
                        <p>
                          The individual or HUF taxpayer may claim a deduction
                          of up to Rs 1 lakh if the elderly person for whose
                          benefit the costs were incurred. Up till FY 2017–18, a
                          senior citizen and a super senior citizen may each
                          claim a deduction of Rs. 60,000 and Rs. 80,000. Unlike
                          before, this is now a standard deduction that is
                          available to all senior citizens, including super
                          senior citizens, up to Rs 1 lakh.
                        </p>

                        <h6>
                          <strong>For reimbursement claims</strong>
                        </h6>
                        <p>
                          The amount of the deduction that the taxpayer may
                          claim under this section shall be reduced by any
                          reimbursement of medical expenses by an insurer or
                          employer.
                        </p>
                        <p>
                          Also keep in mind that in order to claim such a
                          deduction, you must obtain a prescription for such
                          medical treatment from the relevant specialist. Take a
                          look at our in-depth article on Section80DDB
                        </p>
                      </div>
                    </section>

                    <section id='Section80U' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>Section 80U – Physical Disability</h2>
                        </header>
                        <h5>
                          <strong>
                            Deduction for Person suffering from Physical
                            Disability
                          </strong>
                        </h5>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>A resident
                          with a physical impairment (including blindness) or
                          mental retardation is eligible for a deduction of Rs.
                          75,000. One may be eligible for a deduction of Rs.
                          1,25,000 in cases of extreme disability.
                        </p>

                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          Section 80U deduction limits for FY 2015–16 were
                          increased from Rs. 50,000 to Rs. 75,000 and from Rs.
                          1,000,000 to Rs. 1,25,000.
                        </p>
                      </div>
                    </section>

                    <section id='Section80G' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>Section 80G – Donations</h2>
                        </header>
                        <h5>
                          <strong>
                            Deduction for donations towards Social Causes
                          </strong>
                        </h5>

                        <p>
                          The various donations listed in section 80G may be
                          deducted up to 100% or 50%, with or without
                          limitations.
                        </p>
                        <p>
                          Any cash donations made in excess of Rs 2,000 will no
                          longer be deductible as of FY 2017–18. To qualify for
                          an 80G deduction, donations over Rs 2000 must be made
                          using a different payment method than cash.
                        </p>

                        <h6>
                          <strong>
                            Donations with 100% deduction without any qualifying
                            limit
                          </strong>
                        </h6>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>National
                          Defense Fund set up by the Central Government
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>Prime
                          Minister’s National Relief Fund
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>National
                          Foundation for Communal Harmony
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>An approved
                          university/educational institution of National
                          eminence
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>Zila
                          Saksharta Samiti constituted in any district under the
                          chairmanship of the Collector of that district
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>Fund set up
                          by a State Government for the medical relief to the
                          poor
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>National
                          Illness Assistance Fund
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>National
                          Blood Transfusion Council or to any State Blood
                          Transfusion Council
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>National
                          Trust for Welfare of Persons with Autism, Cerebral
                          Palsy, Mental Retardation and Multiple Disabilities
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>National
                          Children's, Sports and Cultural Fund
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>Fund for
                          Technology Development and Application
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>Chief
                          Minister’s Relief Fund or Lieutenant Governor’s Relief
                          Fund with respect to any State or Union Territory
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>The Army
                          Central Welfare Fund or the Indian Naval Benevolent
                          Fund or the Air Force Central Welfare Fund, Andhra
                          Pradesh Chief Minister’s Cyclone Relief Fund, 1996
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>The
                          Maharashtra Chief Minister’s Relief Fund during
                          October 1, 1993 and October 6,1993
                        </p>

                        <h6>
                          <strong>
                            Donations with 50% deduction without any qualifying
                            limit
                          </strong>
                        </h6>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>Jawaharlal
                          Nehru Memorial Fund
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>Prime
                          Minister’s Drought Relief Fund
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>Indira
                          Gandhi Memorial Trust
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>The Rajiv
                          Gandhi Foundation
                        </p>

                        <h6>
                          <strong>
                            Contribution to the following is tax deductible at
                            100% up to 10% of adjusted gross total income.
                          </strong>
                        </h6>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>To be used
                          by the government or any recognized local authority,
                          institution, or group to advance family planning
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          Contribution by a Company to the Indian Olympic
                          Association or to any other authorized organization or
                          institution operating in India for the purpose of
                          building facilities for sports and games there or of
                          sponsoring sports and games there.
                        </p>

                        <h6>
                          <strong>
                            Donations to the following are eligible for 50%
                            deduction subject to 10% of adjusted gross total
                            income
                          </strong>
                        </h6>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>Any other
                          institution or fund that complies with Section 80G(5)
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          To be used by the government or any local authority
                          for any philanthropic endeavors other than to support
                          family planning
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          Any organization created in India with the intent to
                          address and address the housing accommodation needs or
                          to plan, develop, or improve cities, towns, villages,
                          or both.
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          Any company listed in Section 10(26BB) for advancing
                          the interests of the minority community
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          For any notified temple, mosque, gurudwara, church, or
                          other building that needs repairs or renovations.
                        </p>
                      </div>
                    </section>

                    <section id='Section80GGB' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>Section 80GGB – Company Contribution</h2>
                        </header>
                        <h5>
                          <strong>
                            Deduction on contributions given by companies to
                            Political Parties
                          </strong>
                        </h5>
                        <p>
                          Article 80GGB An Indian firm is permitted to deduct
                          its contributions to political parties or electoral
                          trusts. For contributions made in any manner other
                          than cash, deductions are permitted.
                        </p>
                      </div>
                    </section>

                    <section id='Section80GGC' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>
                            Section 80GGC – Contribution to Political Parties
                          </h2>
                        </header>
                        <h5>
                          <strong>
                            Deduction on contributions given by any person to
                            Political Parties
                          </strong>
                        </h5>
                        <p>
                          Any sum given to a political party or electoral trust
                          may be deducted by an individual taxpayer under
                          section 80GGC. Companies, municipal governments, and
                          artificial juridical persons sponsored entirely or
                          partially by the government are not eligible for it.
                          Only if you pay in a method other than cash are you
                          eligible for this deduction.
                        </p>
                      </div>
                    </section>

                    <section id='Section80RRB' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>Section 80RRB – Royalty of a Patent</h2>
                        </header>
                        <h5>
                          <strong>
                            Deduction with respect to any Income by way of
                            Royalty of a Patent
                          </strong>
                        </h5>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          80RRB Deduction is possible for up to Rs. 3 lakh or
                          the amount of the income received, whichever is
                          smaller, for any royalties earned for patents
                          registered on or after April 1, 2003 under the Patents
                          Act, 1970.
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          The taxpayer must be an Indian resident and an
                          individual patentee. A certificate in the required
                          format, duly signed by the required authority, must be
                          provided by the taxpayer.
                        </p>
                      </div>
                    </section>

                    <section id='Section80TTB ' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>Section 80 TTB – Interest Income</h2>
                        </header>
                        <h5>
                          <strong>
                            Deduction of Interest on Deposits for Senior
                            Citizens
                          </strong>
                        </h5>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          With the introduction of a new section 80TTB in the
                          2018 budget, senior citizen deposit interest income
                          will now be eligible for deductions. The maximum
                          deduction allowed is Rs. 50,000.
                        </p>
                        <p>
                          <i className='bi bi-check-circle-fill'></i>
                          There will be no further deductions permitted under
                          section 80TTA. The threshold level for TDS on interest
                          income payable to seniors will also be increased by
                          amending section 194A of the Act in addition to
                          section 80 TTB. According to the most recent Budget,
                          the former cap of Rs 10,000 was raised to Rs 50,000.
                        </p>
                      </div>
                    </section>

                    <section id='Section80' className='mb-0'>
                      <div>
                        <header className='section-header mt-5'>
                          <h2>Section 80 Deductions Summary Table</h2>
                        </header>
                        <table className='table table-hover table-striped table-bordered'>
                          <thead className='bg-info text-white'>
                            <tr>
                              <td>
                                <strong>Section</strong>
                              </td>
                              <td>
                                <strong>Deduction on</strong>
                              </td>
                              <td>
                                <strong>FY 2021-2022</strong>
                              </td>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>
                                <strong>80C</strong>
                              </td>
                              <td>
                                <h6>
                                  <strong>Investment in PPF</strong>
                                </h6>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Employee’s share of PF contribution
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Life Insurance Premium payment
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Tuition Fee for Children’s
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Principal Repayment of home loan
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Investment in Sukanya Samridhi Account
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  NSC's,ULIPS,ELSS
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>Sum
                                  paid to purchase deferred annuity
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Five year deposit scheme
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Senior Citizens savings scheme
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Subscription to notified securities/notified
                                  deposits scheme
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Contribution to notified Pension Fund set up
                                  by Mutual Fund or UTI.
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Subscription to Home Loan Account scheme of
                                  the National Housing Bank
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Subscription to deposit scheme of a public
                                  sector or company engaged in providing housing
                                  finance
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Contribution to notified annuity Plan of LIC
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Subscription to equity shares/ debentures of
                                  an approved eligible issue
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Subscription to notified bonds of NABARD
                                </p>
                              </td>
                              <td>Rs.1,50,000</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>80CCC</strong>
                              </td>
                              <td>
                                For amount deposited in annuity plan of LIC or
                                any other insurer for a pension from a fund
                                referred to in Section 10(23AAB)
                              </td>
                              <td>-</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>80CCD(1)</strong>
                              </td>
                              <td>
                                Employee’s contribution to NPS account (maximum
                                up to Rs 1,50,000)
                              </td>
                              <td>-</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>80CCD(2)</strong>
                              </td>
                              <td>Employer’s contribution to NPS account</td>
                              <td>Maximum up to 10% of salary</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>80CCD(1B)</strong>
                              </td>
                              <td>Additional contribution to NPS</td>
                              <td>Rs. 50,000</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>80TTA(1)</strong>
                              </td>
                              <td>Interest Income from Savings account</td>
                              <td>Maximum up to 10,000</td>
                            </tr>

                            <tr>
                              <td>
                                <strong>80TTB</strong>
                              </td>
                              <td>
                                Exemption of interest from banks, post office,
                                etc. Applicable only to senior citizens
                              </td>
                              <td>Maximum up to 50,000</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>80GG</strong>
                              </td>
                              <td>
                                For rent paid when HRA is not received from
                                employer
                              </td>
                              <td>
                                Least of :
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Rent paid minus 10% of total income
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>Rs.
                                  5000/- per month
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>25%
                                  of total income
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>80E</strong>
                              </td>
                              <td>Interest on education loan</td>
                              <td>Interest paid for a period of 8 years</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>80EE</strong>
                              </td>
                              <td>
                                Interest on home loan for first time home owners
                              </td>
                              <td>Rs 50,000</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>80D</strong>
                              </td>
                              <td>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Medical Insurance – Self, spouse, children
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Medical Insurance – Parents more than 60 years
                                  old or (from FY 2015-16) uninsured parents
                                  more than 80 years old
                                </p>
                              </td>
                              <td>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>Rs.
                                  25,000
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>Rs.
                                  50,000
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>80DD</strong>
                              </td>
                              <td>
                                <p>
                                  Medical treatment for handicapped dependent or
                                  payment to specified scheme for maintenance of
                                  handicapped dependent
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Disability is 40% or more but less than 80%
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Disability is 80% or more
                                </p>
                              </td>
                              <td>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>Rs.
                                  75,000
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>Rs.
                                  1,25,000
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>80DDB</strong>
                              </td>
                              <td>
                                <p>
                                  Medical Expenditure on Self or Dependent
                                  Relative for diseases specified in Rule 11DD
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>For
                                  less than 60 years old
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>For
                                  more than 60 years old
                                </p>
                              </td>
                              <td>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Lower of Rs 40,000 or the amount actually paid
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>
                                  Lower of Rs 1,00,000 or the amount actually
                                  paid
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>80U</strong>
                              </td>
                              <td>
                                <p>Self-suffering from disability :</p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>An
                                  individual suffering from a physical
                                  disability (including blindness) or mental
                                  retardation.
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>An
                                  individual suffering from severe disability
                                </p>
                              </td>
                              <td>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>Rs
                                  75,000
                                </p>
                                <p>
                                  <i className='bi bi-check-circle-fill'></i>Rs
                                  1,25,000
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>80GGB</strong>
                              </td>
                              <td>
                                <p>
                                  Contribution by companies to political parties
                                </p>
                              </td>
                              <td>
                                <p>
                                  Amount contributed (not allowed if paid in
                                  cash)
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>80GGC</strong>
                              </td>
                              <td>
                                <p>
                                  Contribution by individuals to political
                                  parties
                                </p>
                              </td>
                              <td>
                                <p>
                                  Amount contributed (not allowed if paid in
                                  cash)
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>80RRB</strong>
                              </td>
                              <td>
                                <p>
                                  Deductions on Income by way of Royalty of a
                                  Patent
                                </p>
                              </td>
                              <td>
                                <p>Lower of Rs 3,00,000 or income received</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </section>
                  </div>
                </div>
                <div className='col-md-1'></div>
              </div>
            </div>
          </section>

          <Cta />
          <Guidance />

          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
