import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation } from "swiper";


export default function App() {
  return (
    <>
      <section
        id='testimonials'
        className='testimonials aos-init aos-animate'
        data-aos='fade-up'
        data-aos-offset='100'
      >
        <div className='container'>
          <header className='section-header mt-5'>
            <h2>Testimonials</h2>
            <div className='bar'></div>
          </header>
          <div className='clients-slider swiper'>
            <Swiper
              modules={[Autoplay, EffectFade, Navigation]}
              Effect
              speed={100}
              slidesPerView={3}
              loop={true}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },

                1200: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
            >
              <div className='swiper-wrapper align-items-center'>
                <div className='testimonials-slider swiper'>
                  <div className='swiper-wrapper'>
                    <SwiperSlide>
                      <div className='swiper-slide'>
                        <div className='testimonial-item'>
                          <img
                            src='/imgs/icons/startup.png'
                            alt='Test1'
                            className='img-fluid'
                          />
                          <p>
                            Best Tax Consultancy service in Hosur,excellent
                            customers service with affordable price range for
                            each and every services.
                          </p>
                          <div>
                            <h3>Baskar</h3>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='swiper-slide'>
                        <div className='testimonial-item'>
                          <img
                            src='/imgs/icons/conformance.png'
                            alt='Test1'
                            className='img-fluid'
                          />
                          <p>
                            One of the best online tax filing service providers
                            i ever seen,with best customer support for the all
                            types of tax and legal consultancy.
                          </p>
                          <div>
                            <h3>Arun</h3>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='swiper-slide'>
                        <div className='testimonial-item'>
                          <img
                            src='/imgs/icons/consultant.png'
                            alt='Test1'
                            className='img-fluid'
                          />
                          <p>
                            TODAYFILINGS is the top most success rated tax
                            filing service,also online legal consultancy and
                            live video call options for the e-filing service.
                          </p>
                          <div>
                            <h3>Vijay</h3>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    {/* <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="testimonial-item">
                          <p>
                            Fugiat enim eram quae cillum dolore dolor amet nulla
                            culpa multos export minim fugiat minim velit minim dolor
                            enim duis veniam ipsum anim magna sunt elit fore quem
                            dolore labore illum veniam.
                          </p>
                          <div>
                            <h3>Matt Brandon</h3>
                            <h4>Freelancer</h4>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="testimonial-item">
                          <p>
                            Quis quorum aliqua sint quem legam fore sunt eram irure
                            aliqua veniam tempor noster veniam enim culpa labore
                            duis sunt culpa nulla illum cillum fugiat legam esse
                            veniam culpa fore nisi cillum quid.
                          </p>
                          <div>
                            <h3>John Larson</h3>
                            <h4>Entrepreneur</h4>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide> */}
                  </div>
                  <div className='swiper-pagination'></div>
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}
